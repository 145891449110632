import { Typography } from '@sbf/fairplay-nike';
import { useState } from 'react';
import {
  DesktopView,
  MobileView,
} from '@/common/components/Breakpoints/Breakpoints';
import Container from '../Container/Container';
import { TransactionalFooterCard } from '../TransactionalFooterCard/TransactionalFooterCard';
import { SectionTitleButton } from '../TransactionalFooterCard/TransactionalFooterCard.styled';
import { TransactionalFooterSubMenu } from '../TransactionalFooterSubMenu/TransactionalFooterSubMenu';
import { transactionalFooterMock } from './TransactionalFooter.mock';
import {
  CategoryContainer,
  FooterContainer,
  ListLinkContainer,
  ListTitleContainer,
  MainFooter,
  ShowMoreButton,
} from './TransactionalFooter.styled';

const TransactionalFooter = () => {
  const [isOpen, setOpen] = useState(false);
  const buttonText = isOpen ? 'Mostrar menos' : 'Mostrar mais';
  const [categoryItem, setCategoryItem] = useState('Calçados');

  return (
    <FooterContainer>
      <Container>
        <MobileView>
          <Typography variant="heading" size="h2">
            Produtos mais buscados
          </Typography>
          <ListLinkContainer>
            <ListTitleContainer>
              {transactionalFooterMock.map(({ title, id }) => (
                <SectionTitleButton
                  selected={categoryItem === title}
                  key={id}
                  onClick={() => setCategoryItem(title)}
                >
                  {title}
                </SectionTitleButton>
              ))}
            </ListTitleContainer>
          </ListLinkContainer>
          <>
            {transactionalFooterMock
              .filter((item) => item.title == categoryItem)
              .map(({ id, subMenu }) => (
                <TransactionalFooterSubMenu
                  key={id}
                  subMenu={subMenu}
                  isOpen={isOpen}
                  setOpen={setOpen}
                  buttonText={buttonText}
                />
              ))}
          </>
        </MobileView>
        <DesktopView shouldRenderOnServer>
          <MainFooter isOpen={isOpen}>
            <Typography variant="heading" size="h2">
              Produtos mais buscados
            </Typography>
            <ListTitleContainer>
              {transactionalFooterMock.map(({ title }) => (
                <SectionTitleButton
                  key={title}
                  selected={categoryItem === title}
                  onClick={() => setCategoryItem(title)}
                >
                  {title}
                </SectionTitleButton>
              ))}
            </ListTitleContainer>
            <CategoryContainer>
              {transactionalFooterMock
                .filter((item) => item.title == categoryItem)
                .map(({ id, title, subMenu }) => (
                  <TransactionalFooterCard
                    setCategoryItem={setCategoryItem}
                    subMenu={subMenu}
                    key={id}
                    title={title}
                  />
                ))}
            </CategoryContainer>
          </MainFooter>
        </DesktopView>
        <DesktopView>
          <ShowMoreButton onClick={() => setOpen(!isOpen)}>
            {buttonText}
          </ShowMoreButton>
        </DesktopView>
      </Container>
    </FooterContainer>
  );
};

export default TransactionalFooter;
