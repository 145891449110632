import { Accordion, AccordionSummary, Typography } from '@sbf/fairplay-nike';
import Link from 'next/link';
import {
  AccordionTitle,
  ClosedIcon,
  ExpandedIcon,
  ShowMoreButton,
} from '../TransactionalFooter/TransactionalFooter.styled';
import { LinkAnchor } from '../TransactionalFooterCard/TransactionalFooterCard.styled';
import {
  ContentDiv,
  ContentDivList,
  ContentList,
} from './TransactionalFooterSubMenu.styled';
import { TransactionalFooterSubMenuProps } from './TransactionalFooterSubMenu.type';

export const TransactionalFooterSubMenu = ({
  subMenu,
  isOpen,
  setOpen,
  buttonText,
}: TransactionalFooterSubMenuProps) => {
  return (
    <>
      {subMenu.map(({ title, id, items }) => (
        <Accordion key={id}>
          <AccordionSummary
            expandedIcon={<ExpandedIcon />}
            closedIcon={<ClosedIcon />}
            aria-controls={`${id}-content`}
            id={`${id}-header`}
          >
            <AccordionTitle>{title}</AccordionTitle>
          </AccordionSummary>
          <ContentDiv isOpen={isOpen}>
            <ContentDivList isOpen={isOpen}>
              {items.map(({ id, title, link }) => (
                <ContentList key={id}>
                  <Typography variant="paragraph" size="medium">
                    <Link href={link} legacyBehavior passHref>
                      <LinkAnchor
                        type="brand"
                        aria-label="teste"
                        size="medium"
                        text={title}
                      />
                    </Link>
                  </Typography>
                </ContentList>
              ))}
            </ContentDivList>
            <ShowMoreButton onClick={() => setOpen(!isOpen)}>
              {buttonText}
            </ShowMoreButton>
          </ContentDiv>
        </Accordion>
      ))}
    </>
  );
};

export default TransactionalFooterSubMenu;
