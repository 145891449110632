import dynamic from 'next/dynamic';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { namedComponent } from '@/common/utils/async';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  EventsHomeProps,
  sendEventHome,
} from '@/modules/home/tracking/homeTracking';
import {
  BannerButtons as BannerButtonsProps,
  CommonComponent,
  Component,
  DynamicPlaylist as DynamicPlaylistProps,
  FullBanner as FullBannerProps,
  HOME_COMPONENTS,
  MainBanner as MainBannerProps,
  Recommendation as RecommendationProps,
  RotativeBanner as RotativeBannerProps,
  Spacer as SpacerProps,
  Video as VideoProps,
} from '@/services/home/home.types';
import BannerButtons from '../../components/BannerButtons/BannerButtons';

const HeroVideo = dynamic(() =>
  namedComponent(
    import('@/modules/home/components/HeroVideo/HeroVideo'),
    'default',
  ),
);

const BaseBanner = dynamic(() =>
  namedComponent(
    import('@/modules/home/components/BaseBanner/BaseBanner'),
    'default',
  ),
);

const DynamicPlaylist = dynamic(() =>
  namedComponent(
    import('@/modules/home/components/DynamicPlaylist/DynamicPlaylist'),
    'default',
  ),
);

const RotativeBanner = dynamic(() =>
  namedComponent(
    import('@/modules/home/components/RotativeBanner/RotativeBanner'),
    'default',
  ),
);

const Recommendation = dynamic(() =>
  namedComponent(
    import(
      '@/common/components/RecommendationContainer/RecommendationContainer'
    ),
    'default',
  ),
);

const Spacer = dynamic(() =>
  namedComponent(import('@/modules/home/components/Spacer/Spacer'), 'default'),
);

const SpacerWrapper = (props: Component) => {
  const spacerProps = props as SpacerProps & CommonComponent;

  return (
    <Spacer
      title={spacerProps.titulo}
      height={spacerProps.altura}
      position={spacerProps.posicaoTitulo}
    />
  );
};

const RotativeBannerWrapper = (props: Component) => {
  const rotativeProps = props as RotativeBannerProps & CommonComponent;
  const { handleEvents } = useTrackingContext();

  const items = rotativeProps.listaLinks.map((item) => {
    return {
      id: item.ordem,
      href: item.urlLink,
      src: item.urlImagem,
      width: `${item.largura}rem`,
      height: `${item.altura}rem`,
      alt: item.atributoEvento,
      onClick: () => {
        handleEvents<EventsHomeProps>(sendEventHome, {
          component: 'home',
          microComponent: 'banner rotativo',
          position: `posição: ${rotativeProps.ordem} - Imagem: ${item.ordem}`,
          title: rotativeProps.tituloElemento,
          url: item.urlLink,
          contentType: 'navigation_link',
        });
      },
    };
  });

  return (
    <RotativeBanner
      items={items}
      transitionTime={rotativeProps.tempoParaTransicao}
      showArrows={rotativeProps.exibirIndicadorDeTransicao}
    />
  );
};

const FullBanner = (props: Component) => {
  const fullBannerProps = props as FullBannerProps & CommonComponent;
  const fullBanner = fullBannerProps.bannerFull;
  const { handleEvents } = useTrackingContext();
  return (
    <BaseBanner
      href={fullBanner.urlLink}
      src={fullBanner.urlImagem}
      width={fullBanner.largura}
      height={fullBanner.altura}
      alt={fullBanner.atributoEvento}
      priority={fullBannerProps.isFirstComponent}
      testId="full-banner"
      onClick={() =>
        handleEvents<EventsHomeProps>(sendEventHome, {
          component: 'home',
          microComponent: 'full banner',
          position: `posição: ${fullBannerProps.ordem}`,
          title: `${fullBanner.atributoEvento}`,
          url: fullBanner.urlLink,
          contentType: 'navigation_link',
        })
      }
    />
  );
};

const FullBannerWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(FullBanner);

const DynamicPlaylistWrapper = (props: Component) => {
  const dynamicPlaylistProps = props as DynamicPlaylistProps & CommonComponent;
  const { handleEvents } = useTrackingContext();

  const playlist = dynamicPlaylistProps.linhas.map((row) => {
    return {
      id: row.ordem,
      columns: row.colunas.map(
        ({
          ordem,
          urlLink,
          urlImagem,
          largura = '500',
          altura = '500',
          atributoEvento,
        }) => {
          return {
            id: ordem,
            href: urlLink,
            src: urlImagem,
            width: largura,
            height: altura,
            alt: atributoEvento,
            onClick: () =>
              handleEvents<EventsHomeProps>(sendEventHome, {
                component: 'home',
                microComponent: 'playlist dinâmica',
                position: `posição: ${dynamicPlaylistProps.ordem} - Linha ${row.ordem} Coluna ${ordem}`,
                title: atributoEvento || 'atributoEvento não cadastrado',
                url: urlLink,
                contentType: 'navigation_link',
              }),
          };
        },
      ),
    };
  });
  return <DynamicPlaylist playlist={playlist} />;
};

const DynamicPlaylistWrapperWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(DynamicPlaylistWrapper);

const MainBanner = (props: Component) => {
  const mainBanner = props as MainBannerProps & CommonComponent;
  const { handleEvents } = useTrackingContext();

  // It is only possible to register a single banner in the admin,
  // but for some reason the API returns an array
  const banner = mainBanner.listaLinks[0];

  return (
    <BaseBanner
      href={banner.urlLink}
      src={banner.urlImagem}
      width={`${banner.largura}rem`}
      height={`${banner.altura}rem`}
      alt={banner.atributoEvento}
      priority={mainBanner.isFirstComponent}
      testId="main-banner"
      onClick={() =>
        handleEvents<EventsHomeProps>(sendEventHome, {
          component: 'home',
          microComponent: 'main banner',
          position: `posição: ${mainBanner.ordem}`,
          title: banner.atributoEvento,
          url: banner.urlLink,
        })
      }
    />
  );
};

const MainBannerWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(MainBanner);

const RecommendationWrapper = (props: Component) => {
  const recommendationProps = props as RecommendationProps & CommonComponent;

  const position = recommendationProps.vitrineChaordic?.TipoVitrineChaordicDesk;
  const label = { 1: 'top', 2: 'middle' }[position] ?? 'bottom';

  return <Recommendation label={label} />;
};

const RecommendationWrapperWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(RecommendationWrapper);

const VideoWrapper = (props: Component) => {
  const {
    video: { url, autoplay, loop, controles, posterUrl },
  } = props as VideoProps;
  const videoProps = props as VideoProps & CommonComponent;
  return (
    <HeroVideo
      srcSet={[url]}
      poster={posterUrl}
      controls={controles}
      loop={loop}
      autoPlay={autoplay}
      attribute={props.tituloElemento || posterUrl}
      component="home"
      microComponent="home-video"
      homePosition={videoProps.ordem.toString()}
    />
  );
};

const VideoWrapperWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(VideoWrapper);

const ButtonsWrapper = (props: Component) => {
  const buttonsProps = props as BannerButtonsProps & CommonComponent;
  const { margemSuperior, margemInferior, botoes } =
    props as BannerButtonsProps;

  const buttons = botoes.map(({ titulo, link }) => ({ link, label: titulo }));
  const config = {
    paddingTop: margemSuperior ? `${margemSuperior}rem` : undefined,
    paddingBottom: margemInferior ? `${margemInferior}rem` : undefined,
    position: 2,
  };

  return (
    <BannerButtons
      config={config}
      buttons={buttons}
      homePosition={buttonsProps.ordem.toString()}
    />
  );
};

const ButtonsWrapperWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible'],
})(ButtonsWrapper);

const HOME_BUILDER = {
  [HOME_COMPONENTS.FULL_BANNER]: FullBanner,
  [HOME_COMPONENTS.SPACER]: SpacerWrapper,
  [HOME_COMPONENTS.DYNAMIC_PLAYLIST]: DynamicPlaylistWrapper,
  [HOME_COMPONENTS.ROTATIVE_BANNER]: RotativeBannerWrapper,
  [HOME_COMPONENTS.MAIN_BANNER]: MainBanner,
  [HOME_COMPONENTS.RECOMMENDATION]: RecommendationWrapper,
  [HOME_COMPONENTS.RECOMMENDATION_MOBILE]: RecommendationWrapper,
  [HOME_COMPONENTS.VIDEO]: VideoWrapper,
  [HOME_COMPONENTS.BANNER_BUTTONS]: ButtonsWrapper,
};

const HOME_BUILDER_WITH_HYDRATATION_ON_DEMAND = {
  [HOME_COMPONENTS.FULL_BANNER]: FullBannerWithHydrationOnDemand,
  [HOME_COMPONENTS.SPACER]: SpacerWrapper,
  [HOME_COMPONENTS.DYNAMIC_PLAYLIST]:
    DynamicPlaylistWrapperWithHydrationOnDemand,
  [HOME_COMPONENTS.ROTATIVE_BANNER]: RotativeBannerWrapper,
  [HOME_COMPONENTS.MAIN_BANNER]: MainBannerWithHydrationOnDemand,
  [HOME_COMPONENTS.RECOMMENDATION]: RecommendationWrapperWithHydrationOnDemand,
  [HOME_COMPONENTS.RECOMMENDATION_MOBILE]:
    RecommendationWrapperWithHydrationOnDemand,
  [HOME_COMPONENTS.VIDEO]: VideoWrapperWithHydrationOnDemand,
  [HOME_COMPONENTS.BANNER_BUTTONS]: ButtonsWrapperWithHydrationOnDemand,
};

export const HomeBuilder = (props: Component, index: number) => {
  // the first 3 components are rendered without hydration on demand
  const Component =
    index < 3
      ? HOME_BUILDER[props.tipoElemento]
      : HOME_BUILDER_WITH_HYDRATATION_ON_DEMAND[props.tipoElemento];

  if (!Component) {
    return null;
  }

  const FIRST_PHOTO_POSITION = 0;
  const isFirstComponent = index === FIRST_PHOTO_POSITION;

  return (
    <Component
      key={props.ordem}
      {...props}
      isFirstComponent={isFirstComponent}
    />
  );
};
