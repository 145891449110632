import { AccordionDetails } from '@sbf/fairplay-nike';
import styled, { css } from 'styled-components';

export const ContentDiv = styled(AccordionDetails)<{ isOpen: boolean }>`
  ${({ isOpen }) => css`
    height: 200px;
    ${isOpen &&
    css`
      height: auto;
    `}
  `}
`;

export const ContentDivList = styled.div<{ isOpen?: boolean }>`
  ${({ isOpen }) => css`
    height: 180px;
    overflow: hidden;
    ${isOpen &&
    css`
      height: auto;
    `}
  `}
`;

export const ContentList = styled.li`
  ${({ theme }) => css`
    margin-bottom: ${theme.spaces[400]};
  `}
`;
