import { ArrowDown, ArrowTop } from '@sbf/fairplay-icons';
import { Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const TransactionFooterContainer = styled.footer`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
  `}
`;

export const AccordionTitle = styled(Typography).attrs({
  variant: 'paragraph',
  size: 'medium',
})`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[700]};
    font-size: ${theme.fontSizes.large};
    font-weight: ${theme.fontWeights.medium};
  `}
`;

export const ExpandedIcon = styled(ArrowTop)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

export const ClosedIcon = styled(ArrowDown)`
  ${({ theme }) => css`
    color: ${theme.colors.primary};
  `}
`;

export const MainFooter = styled.div<{
  isOpen?: boolean;
}>`
  ${({ theme, isOpen }) => css`
    ${media.greaterThan('desktop')} {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-content: center;
      height: 310rem;
      margin: ${theme.spaces[0]} auto;
      overflow: hidden;

      ${isOpen &&
      css`
        height: auto;
      `}
    }
  `}
`;

export const FooterContainer = styled.section.attrs({
  id: 'transactional-footer',
})`
  ${({ theme }) => css`
    margin-bottom: ${theme.spaces[600]};
    margin-top: ${theme.spaces[800]};
  `}
`;

export const ShowMoreButton = styled.button`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    background-color: ${theme.colors.neutral[100]};
    border: none;
    border-bottom: ${theme.borderWidth.thin} solid ${theme.colors.primary};
    cursor: pointer;
    font-size: ${theme.fontSizes.medium};
    margin: ${theme.spaces[300]} auto ${theme.spaces[400]};
  `}
`;

export const ListTitleContainer = styled.li`
  ${({ theme }) => css`
    display: flex;
    width: calc(100vw - 60rem);
    ${media.greaterThan('desktop')} {
      width: 70%;
      gap: ${theme.spaces[700]};
    }
  `}
`;

export const CategoryContainer = styled.div`
  display: flex;
`;

export const ListLinkContainer = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  ${media.greaterThan('tablet')} {
    flex-flow: row wrap;
  }
`;
