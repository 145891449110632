import { Button } from '@sbf/fairplay-core';
import type { ButtonVariants } from '@sbf/fairplay-core';
import Link from 'next/link';
import isEmpty from '@/common/utils/funcs/isEmpty/isEmpty';
import { useTrackingContext } from '@/contexts/TrackingContext';
import {
  SelectContentObject,
  sendSelectContentEvents,
} from '@/helpers/analytics';
import { BannerContainer } from './BannerButtons.styled';

export type BannerButtonsProps = {
  buttons: BannerButton[];
  config?: BannerButtonsConfig;
  homePosition?: string;
};

export type BannerButton = {
  label: string;
  link: string;
  color?: ButtonVariants['color'];
  variant?: ButtonVariant;
};

type ButtonVariant = 'fill' | 'outline';

export type BannerButtonsConfig = {
  paddingTop?: string;
  paddingBottom?: string;
  position: BANNER_POSITION;
  onClick?: () => void;
};

export enum BANNER_POSITION {
  CENTER = 0,
  END = 1,
  START = 2,
}

const BUTTON_VARIANT_MAP = { fill: Button.Fill, outline: Button.Outline };

const getButtonByVariant = (variant?: ButtonVariant) => {
  if (!variant || !BUTTON_VARIANT_MAP[variant]) {
    return Button.Fill;
  }
  return BUTTON_VARIANT_MAP[variant];
};

const BannerButtons = ({
  buttons,
  config = { position: 2 },
  homePosition,
}: BannerButtonsProps) => {
  const { handleEvents } = useTrackingContext();

  if (isEmpty(buttons)) {
    return <></>;
  }

  return (
    <BannerContainer
      paddingBottom={config?.paddingBottom}
      paddingTop={config?.paddingTop}
      position={config?.position}
      data-testid="banner-button-container"
    >
      {buttons.slice(0, 3).map(({ label, link, color, variant }) => {
        const ButtonComponent = getButtonByVariant(variant);
        const buttonColor = color ?? 'primary';
        return (
          <Link key={label} href={link} legacyBehavior passHref>
            <ButtonComponent
              as="a"
              color={buttonColor}
              data-testid="banner-button"
              onClick={() => {
                handleEvents<SelectContentObject>(sendSelectContentEvents, {
                  component: 'home',
                  microComponent: 'banner-botoes',
                  anchorText: `posição: ${homePosition}`,
                  title: label,
                  url: link,
                  contentType: 'click_botoes',
                });
                config?.onClick;
              }}
            >
              {label}
            </ButtonComponent>
          </Link>
        );
      })}
    </BannerContainer>
  );
};

export default BannerButtons;
