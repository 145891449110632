import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';
import { variant } from '@/helpers/styles';

type BannerContainerProps = {
  paddingTop?: string;
  paddingBottom?: string;
  position: number;
};

export const BannerContainer = styled.div<BannerContainerProps>`
  ${({ theme, paddingBottom, paddingTop, position }) => css`
    display: flex;
    align-items: center;
    justify-content: ${variant(position, {
      0: 'center',
      1: 'flex-end',
      2: 'flex-start',
    })};
    gap: ${theme.spaces[300]};
    flex-wrap: wrap;

    // Since we are still using images for the Text Banners
    // we need to use a variable size. The size is aligned with T4 to be always the same
    // TODO: Remove the variable padding once we implement the Text Banner
    padding-left: 6.93vw;
    padding-right: 6.93vw;
    padding-top: ${paddingTop ?? theme.spaces[400]};
    padding-bottom: ${paddingBottom ?? theme.spaces[400]};

    ${media.greaterThan('tablet')} {
      justify-content: center;
    }
  `}
`;
