import { NextSeo } from 'next-seo';
import { useEffect } from 'react';
import {
  DesktopView,
  MobileView,
} from '@/common/components/Breakpoints/Breakpoints';
import TransactionFooter from '@/common/components/TransactionalFooter/TransactionalFooter';
import { getMetadata } from '@/common/utils/metadata/metadata';
import { trackingEvent } from '@/common/utils/tracking/tracking';
import { HomeBuilder } from '@/modules/home/patterns/HomeBuilder/HomeBuilder';
import { Home as HomeProps } from '@/services/home/home.types';
import { StyledMain } from './Home.styled';

export type HomesPropsType = {
  desktop: HomeProps;
  mobile: HomeProps;
};

const HomeScreen = ({ desktop, mobile }: HomesPropsType) => {
  useEffect(() => {
    trackingEvent({
      home: 'home',
    });
  }, []);

  return (
    <StyledMain>
      <NextSeo {...getMetadata('default')} />
      <MobileView>{mobile.conteudoJson.map(HomeBuilder)}</MobileView>
      <DesktopView shouldRenderOnServer>
        {desktop.conteudoJson.map(HomeBuilder)}
      </DesktopView>
      <TransactionFooter />
    </StyledMain>
  );
};

HomeScreen.displayName = 'HomeScreen';

export default HomeScreen;
