import { trackingEventOLD } from '@/common/utils/tracking/tracking';
import { push } from '@/helpers/dataLayer';

export type EventsHomeProps = {
  component: string;
  microComponent: string;
  position: string;
  title: string;
  url?: string;
  contentType?: string;
};

export const sendEventHome = ({
  component,
  microComponent,
  position,
  title,
  url,
  contentType,
}: EventsHomeProps) => {
  const eventObj = {
    event: 'interaction_home',
    component,
    microComponent,
    position: position,
    contentType,
    title,
    url,
  };
  push(eventObj);
};

export const sendVideoPlayEvent = () => {
  trackingEventOLD({
    category: 'UI - Home - Video',
    action: 'Play - Home Video',
    label: '`Clicou para personalizar o item ${item}`',
  });
};

export const sendVideoPauseEvent = () => {
  trackingEventOLD({
    category: 'UI - Home - Video',
    action: 'Click - Pausou a exibição do video',
    label: `Clicou para pausar a exibição do video da home`,
  });
};

export const sendVideoVolumeChangeEvent = () => {
  trackingEventOLD({
    category: 'UI - Home - Video',
    action: 'Click - Alterar o volume',
    label: `Clicou para alterar o volume do video da home`,
  });
};
