import { TransactionalFooterProps } from './TransactionalFooter.types';

export const transactionalFooterMock: TransactionalFooterProps[] = [
  {
    id: 1,
    title: 'Calçados',
    subMenu: [
      {
        id: 1,
        title: 'Destaques',
        items: [
          {
            id: 1,
            title: 'Tênis casual',
            link: 'https://www.nike.com.br/nav/categorias/tenis/esportes/casual/tipodeproduto/calcados',
          },
          {
            id: 2,
            title: 'Air Jordan 1',
            link: 'https://www.nike.com.br/nav/modelos/Airjordan1',
          },
          {
            id: 3,
            title: 'Tênis cano alto',
            link: 'https://www.nike.com.br/nav/alturadocano/canoalto',
          },
          {
            id: 4,
            title: 'Air Max 90',
            link: 'https://www.nike.com.br/nav/modelos/airmax90',
          },
          {
            id: 5,
            title: 'Air Max Plus',
            link: 'https://www.nike.com.br/nav/modelos/airmaxplus',
          },
          {
            id: 6,
            title: 'Dunk',
            link: 'https://www.nike.com.br/nav/modelos/dunk',
          },
          {
            id: 7,
            title: 'Air Max 1',
            link: 'https://www.nike.com.br/nav/modelos/airmax1',
          },
          {
            id: 8,
            title: 'Tênis',
            link: 'https://www.nike.com.br/nav/categorias/tenis',
          },
        ],
      },
      {
        id: 2,
        title: 'Feminino',
        items: [
          {
            id: 1,
            title: 'Jordan feminino',
            link: 'https://www.nike.com.br/nav/genero/feminino/marca/jordan',
          },
          {
            id: 2,
            title: 'Dunk feminino',
            link: 'https://www.nike.com.br/nav/genero/feminino/idade/adulto/modelos/dunk/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Air Force feminino',
            link: 'https://www.nike.com.br/nav/genero/feminino/idade/adulto/modelos/airforce1/tipodeproduto/calcados',
          },
          {
            id: 4,
            title: 'Air Max 90 feminino',
            link: 'https://www.nike.com.br/nav/genero/feminino/idade/adulto/modelos/airmax90/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Chuteira society feminina',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/genero/feminino/soladodachuteira/society/tipodeproduto/calcados',
          },
          {
            id: 6,
            title: 'Tênis cano médio feminino',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/genero/feminino/soladodachuteira/society/tipodeproduto/calcados',
          },
          {
            id: 7,
            title: 'Chinelo feminino',
            link: 'https://www.nike.com.br/nav/categorias/chinelossandalias/genero/feminino/idade/adulto/tipodeproduto/calcados',
          },
        ],
      },
      {
        id: 3,
        title: 'Masculino',
        items: [
          {
            id: 1,
            title: 'Jordan masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/marca/jordan',
          },
          {
            id: 2,
            title: 'Air Force 1 masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/modelos/airforce1/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Air Jordan 1 masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/modelos/airjordan1/tipodeproduto/calcados',
          },
          {
            id: 4,
            title: 'Air Max 90 masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/modelos/airmax90/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Dunk masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/modelos/dunk/tipodeproduto/calcados',
          },
          {
            id: 6,
            title: 'Tênis cano baixo masculino',
            link: 'https://www.nike.com.br/nav/alturadocano/canobaixo/categorias/tenis/genero/masculino',
          },
          {
            id: 7,
            title: 'Tênis de skate masculino',
            link: 'https://www.nike.com.br/nav/esportes/skateboarding/genero/masculino/idade/adulto/tipodeproduto/calcados',
          },
          {
            id: 8,
            title: 'Dunk low masculino',
            link: 'https://www.nike.com.br/nav/alturadocano/canobaixo/genero/masculino/modelos/dunk',
          },
        ],
      },
      {
        id: 4,
        title: 'Infantil',
        items: [
          {
            id: 1,
            title: 'Air Force 1 infantil',
            link: 'https://www.nike.com.br/nav/idade/infantil/modelos/airforce1/tipodeproduto/calcados',
          },
          {
            id: 2,
            title: 'Air max 90 infantil',
            link: 'https://www.nike.com.br/nav/idade/infantil/modelos/airmax90/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Jordan infantil masculino',
            link: 'https://www.nike.com.br/nav/categorias/tenis/genero/masculino/idade/infantil/marca/jordan',
          },
          {
            id: 4,
            title: 'Tênis casual infantil',
            link: 'https://www.nike.com.br/nav/esportes/casual/idade/infantil/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Chinelo infantil',
            link: 'https://www.nike.com.br/nav/categorias/chinelossandalias/idade/infantil/tipodeproduto/calcados',
          },
          {
            id: 6,
            title: 'Dunk infantil',
            link: 'https://www.nike.com.br/nav/idade/infantil/modelos/dunk/tipodeproduto/calcados',
          },
          {
            id: 7,
            title: 'Chuteira de futsal infantil',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/idade/infantil/marca/nike/soladodachuteira/futsal',
          },
        ],
      },
    ],
  },
  {
    id: 2,
    title: 'Roupas',
    subMenu: [
      {
        id: 1,
        title: 'Destaques',
        items: [
          {
            id: 1,
            title: 'Calça Jordan',
            link: 'https://www.nike.com.br/nav/marca/jordan/tipodecalcasleggings/calcas',
          },
          {
            id: 2,
            title: 'Biquini feminino',
            link: 'https://www.nike.com.br/nav/categorias/maiosbiquinis/genero/feminino',
          },
          {
            id: 3,
            title: 'Camiseta manga longa',
            link: 'https://www.nike.com.br/nav/tipodecamiseta/mangalonga',
          },
          {
            id: 4,
            title: 'Colete masculino',
            link: 'https://www.nike.com.br/nav/genero/masculino/tipodejaquetasmoletons/coletes/tipodeproduto/roupas',
          },
          {
            id: 5,
            title: 'Top nadador',
            link: 'https://www.nike.com.br/nav/categorias/tops/estilotop/costasnadador/tipodeproduto/roupas',
          },
          {
            id: 6,
            title: 'Camiseta do Corinthians',
            link: 'https://www.nike.com.br/nav/esportes/futebol/times/corinthians/tipodecamiseta/camisasdetimes/tipodeproduto/roupas',
          },
          {
            id: 7,
            title: 'Camiseta do Brasil',
            link: 'https://www.nike.com.br/nav/times/brasil',
          },
          {
            id: 8,
            title: 'Camiseta do Barcelona',
            link: 'https://www.nike.com.br/nav/times/barcelona',
          },
        ],
      },
      {
        id: 2,
        title: 'Feminino',
        items: [
          {
            id: 1,
            title: 'Cropped feminino',
            link: 'https://www.nike.com.br/nav/genero/feminino/tipodecamiseta/cropped/tipodeproduto/roupas',
          },
          {
            id: 2,
            title: 'Corta vento feminina',
            link: 'https://www.nike.com.br/nav/genero/feminino/tipodejaquetasmoletons/cortavento',
          },
          {
            id: 3,
            title: 'Regata feminina',
            link: 'https://www.nike.com.br/nav/genero/feminino/tipodecamiseta/regatas/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Vestido feminino',
            link: 'https://www.nike.com.br/nav/categorias/saiasvestidosmacacoes/genero/feminino/tipodeproduto/roupas',
          },
          {
            id: 5,
            title: 'Roupa casual feminina',
            link: 'https://www.nike.com.br/nav/esportes/casual/genero/feminino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 6,
            title: 'Roupa de academia feminina',
            link: 'https://www.nike.com.br/nav/esportes/treinoacademia/genero/feminino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 7,
            title: 'Camiseta de academia feminina',
            link: 'https://www.nike.com.br/nav/categorias/camisetas/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 8,
            title: 'Roupas de futebol feminina',
            link: 'https://www.nike.com.br/nav/esportes/futebol/genero/feminino/idade/adulto/tipodeproduto/roupas',
          },
        ],
      },
      {
        id: 3,
        title: 'Masculino',
        items: [
          {
            id: 1,
            title: 'Regata masculina',
            link: 'https://www.nike.com.br/nav/genero/masculino/tipodecamiseta/regatas/tipodeproduto/roupas',
          },
          {
            id: 2,
            title: 'Blusa de basquete masculina',
            link: 'https://www.nike.com.br/nav/categorias/jaquetasmoletons/esportes/basquete/genero/masculino/idade/adulto',
          },
          {
            id: 3,
            title: 'Corta vento masculina',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/tipodejaquetasmoletons/cortavento/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Roupa de academia masculina',
            link: 'https://www.nike.com.br/nav/esportes/treinoacademia/genero/masculino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 5,
            title: 'Camisa polo masculina',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/tipodecamiseta/polos/tipodeproduto/roupas',
          },
          {
            id: 6,
            title: 'Roupa casual masculina',
            link: 'https://www.nike.com.br/nav/esportes/casual/genero/masculino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 7,
            title: 'Shorts de basquete masculino',
            link: 'https://www.nike.com.br/nav/categorias/shorts/esportes/basquete/genero/masculino/idade/adulto',
          },
          {
            id: 8,
            title: 'Roupa de skate masculina',
            link: 'https://www.nike.com.br/nav/esportes/skateboarding/genero/masculino/idade/adulto/tipodeproduto/roupas',
          },
        ],
      },
      {
        id: 4,
        title: 'Infantil',
        items: [
          {
            id: 1,
            title: 'Camiseta de time infantil',
            link: 'https://www.nike.com.br/nav/alturadocano/canobaixo/categorias/tenis/genero/masculino',
          },
          {
            id: 2,
            title: 'Jaqueta infantil masculina',
            link: 'https://www.nike.com.br/nav/categorias/jaquetasmoletons/genero/masculino/idade/infantil/tipodeproduto/roupas',
          },
          {
            id: 3,
            title: 'Camiseta infantil',
            link: 'https://www.nike.com.br/nav/categorias/camisetas/idade/infantil/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Shorts infantil',
            link: 'https://www.nike.com.br/nav/categorias/shorts/idade/infantil/tipodeproduto/roupas',
          },
          {
            id: 5,
            title: 'Calça infantil',
            link: 'https://www.nike.com.br/nav/categorias/calcasleggings/idade/infantil/tipodeproduto/roupas',
          },
        ],
      },
    ],
  },
  {
    id: 3,
    title: 'Acessórios',
    subMenu: [
      {
        id: 1,
        title: 'Destaques',
        items: [
          {
            id: 1,
            title: 'Bolsa sacola',
            link: 'https://www.nike.com.br/nav/tipodebolsasmochilas/sacolas',
          },
          {
            id: 2,
            title: 'Bolsa sacola masculina',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/tipodebolsasmochilas/sacolas/tipodeproduto/acessorios',
          },
          {
            id: 3,
            title: 'Meia invisível masculina',
            link: 'https://www.nike.com.br/nav/alturadocano/invisivel/categorias/meias/genero/masculino',
          },
          {
            id: 4,
            title: 'Meia feminina',
            link: 'https://www.nike.com.br/nav/categorias/meias/genero/feminino/idade/adulto/tipodeproduto/acessorios',
          },
        ],
      },
      {
        id: 2,
        title: 'Feminino',
        items: [
          {
            id: 1,
            title: 'Pochete feminina',
            link: 'https://www.nike.com.br/nav/genero/feminino/idade/adulto/tipodebolsasmochilas/pochetes/tipodeproduto/acessorios',
          },
          {
            id: 2,
            title: 'Bolsa sacola feminina',
            link: 'https://www.nike.com.br/nav/genero/feminino/idade/adulto/tipodebolsasmochilas/sacolas/tipodeproduto/acessorios',
          },
          {
            id: 3,
            title: 'Meia cano médio',
            link: 'https://www.nike.com.br/nav/alturadocano/canomedio/categorias/meias/marca/nike',
          },
          {
            id: 4,
            title: 'Meia de futebol',
            link: 'https://www.nike.com.br/nav/categorias/meias/esportes/futebol',
          },
          {
            id: 5,
            title: 'Caneleira feminina',
            link: 'https://www.nike.com.br/nav/categorias/caneleiras/genero/feminino/idade/adulto/tipodeproduto/acessorios',
          },
        ],
      },
      {
        id: 3,
        title: 'Masculino',
        items: [
          {
            id: 1,
            title: 'Mochila masculina',
            link: 'https://www.nike.com.br/nav/categorias/bolsasmochilas/genero/masculino/idade/adulto/tipodeproduto/acessorios',
          },
          {
            id: 2,
            title: 'Pochete masculina',
            link: 'https://www.nike.com.br/nav/genero/masculino/idade/adulto/tipodebolsasmochilas/pochetes/tipodeproduto/acessorios',
          },
          {
            id: 3,
            title: 'Caneleira masculina',
            link: 'https://www.nike.com.br/nav/categorias/caneleiras/genero/masculino/tipodeproduto/acessorios',
          },
          {
            id: 4,
            title: 'Luva masculina',
            link: 'https://www.nike.com.br/nav/categorias/luvas/genero/masculino',
          },
        ],
      },
      {
        id: 4,
        title: 'Infantil',
        items: [
          {
            id: 1,
            title: 'Luva infantil',
            link: 'https://www.nike.com.br/nav/categorias/luvas/idade/infantil',
          },
          {
            id: 2,
            title: 'Boné infantil',
            link: 'https://www.nike.com.br/nav/categorias/boneschapeus/idade/infantil',
          },
          {
            id: 3,
            title: 'Chinelo infantil',
            link: 'https://www.nike.com.br/nav/categorias/chinelossandalias/idade/infantil/tipodeproduto/calcados',
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: 'Esportes',
    subMenu: [
      {
        id: 1,
        title: 'Academia',
        items: [
          {
            id: 1,
            title: 'Metcon',
            link: 'https://www.nike.com.br/nav/modelos/metcon',
          },
          {
            id: 2,
            title: 'Blusa de academia feminina',
            link: 'https://www.nike.com.br/nav/categorias/jaquetasmoletons/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 3,
            title: 'Shorts de academia feminino',
            link: 'https://www.nike.com.br/nav/categorias/shorts/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 4,
            title: 'Boné de academia feminino',
            link: 'https://www.nike.com.br/nav/categorias/bonesviseiras/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 5,
            title: 'Meia de academia feminina',
            link: 'https://www.nike.com.br/nav/categorias/meias/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 6,
            title: 'Legging de academia feminina',
            link: 'https://www.nike.com.br/nav/categorias/calcasleggings/esportes/treinoacademia/genero/feminino/idade/adulto',
          },
          {
            id: 7,
            title: 'Saia de academia',
            link: 'https://www.nike.com.br/nav/categorias/saiasvestidosmacacoes/genero/feminino',
          },
        ],
      },
      {
        id: 2,
        title: 'Basquete',
        items: [
          {
            id: 1,
            title: 'Tênis de basquete',
            link: 'https://www.nike.com.br/nav/esportes/basquete/idade/adulto/tipodeproduto/calcados',
          },
          {
            id: 2,
            title: 'Camiseta de basquete masculina',
            link: 'https://www.nike.com.br/nav/categorias/camisetas/esportes/basquete/genero/masculino/idade/adulto',
          },
          {
            id: 3,
            title: 'Roupa de basquete feminina',
            link: 'https://www.nike.com.br/nav/esportes/basquete/genero/feminino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Meia de basquete',
            link: 'https://www.nike.com.br/nav/categorias/meias/esportes/basquete/genero/masculino/idade/adulto',
          },
          {
            id: 4,
            title: 'Tênis de basquete infantil',
            link: 'https://www.nike.com.br/nav/esportes/basquete/idade/infantil/tipodeproduto/calcados',
          },
          {
            id: 4,
            title: 'Roupa de basquete masculina',
            link: 'https://www.nike.com.br/nav/esportes/basquete/genero/masculino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Camiseta de basquete feminina',
            link: 'https://www.nike.com.br/nav/categorias/camisetas/esportes/basquete/genero/feminino/idade/adulto',
          },
        ],
      },
      {
        id: 3,
        title: 'Corrida',
        items: [
          {
            id: 1,
            title: 'Tênis de corrida',
            link: 'https://www.nike.com.br/nav/categorias/tenis/esportes/corrida/tipodeproduto/calcados',
          },
          {
            id: 2,
            title: 'Roupa de corrida feminina',
            link: 'https://www.nike.com.br/nav/esportes/corrida/genero/feminino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 3,
            title: 'Roupa de corrida masculina',
            link: 'https://www.nike.com.br/nav/esportes/corrida/genero/masculino/idade/adulto/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Tênis de corrida infantil',
            link: 'https://www.nike.com.br/nav/esportes/corrida/idade/infantil/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Tênis Pegasus',
            link: 'https://www.nike.com.br/nav/modelos/pegasus',
          },
          {
            id: 6,
            title: 'Invincible Run',
            link: 'https://www.nike.com.br/nav/modelos/invinciblerun',
          },
          {
            id: 7,
            title: 'Alphafly',
            link: 'https://www.nike.com.br/nav/modelos/alphafly',
          },
          {
            id: 8,
            title: 'Infinity Run',
            link: 'https://www.nike.com.br/nav/modelos/infinityrun',
          },
        ],
      },
      {
        id: 4,
        title: 'Futebol',
        items: [
          {
            id: 1,
            title: 'Chuteira society',
            link: 'https://www.nike.com.br/nav/soladodachuteira/society',
          },
          {
            id: 2,
            title: 'Chuteira Phantom',
            link: 'https://www.nike.com.br/nav/modelos/phantom',
          },
          {
            id: 3,
            title: 'Mercurial',
            link: 'https://www.nike.com.br/nav/modelos/mercurial',
          },
          {
            id: 4,
            title: 'Chuteira Tiempo',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/esportes/futebol/modelos/tiempo',
          },
          {
            id: 5,
            title: 'Chuteira',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras',
          },
          {
            id: 6,
            title: 'Chuteira de futsal',
            link: 'https://www.nike.com.br/nav/soladodachuteira/futsal',
          },
          {
            id: 7,
            title: 'Chuteira de futsal feminina',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/genero/feminino/soladodachuteira/futsal',
          },
        ],
      },
    ],
  },
  {
    id: 5,
    title: 'Journal',
    subMenu: [
      {
        id: 1,
        title: 'Corrida',
        items: [
          {
            id: 1,
            title: 'Melhores tênis para corrida',
            link: 'https://www.nike.com.br/journal/melhores-tenis-para-corrida',
          },
          {
            id: 2,
            title: 'Tipos de pisada',
            link: 'https://www.nike.com.br/journal/tipos-de-pisada',
          },
          {
            id: 3,
            title: 'Como começar a correr',
            link: 'https://www.nike.com.br/journal/como-comecar-a-correr',
          },
          {
            id: 4,
            title: 'Treino para correr 5km',
            link: 'https://www.nike.com.br/journal/treino-para-correr-5-km',
          },
          {
            id: 5,
            title: 'Postura para correr',
            link: 'https://www.nike.com.br/journal/postura-correta-para-correr',
          },
          {
            id: 6,
            title: 'O que é pisada pronada',
            link: 'https://www.nike.com.br/journal/pisada-pronada',
          },
          {
            id: 7,
            title: 'O que é pisada neutra',
            link: 'https://www.nike.com.br/journal/pisada-neutra',
          },
        ],
      },
      {
        id: 2,
        title: 'Treino',
        items: [
          {
            id: 1,
            title: 'Melhores tênis para academia',
            link: 'https://www.nike.com.br/journal/qual-melhor-tenis-para-academia',
          },
          {
            id: 2,
            title: 'Biotipos corporais',
            link: 'https://www.nike.com.br/journal/biotipos-corporais',
          },
          {
            id: 3,
            title: 'Exercícios de cardio',
            link: 'https://www.nike.com.br/journal/exercicios-de-cardio',
          },
          {
            id: 4,
            title: 'Hábitos saudáveis',
            link: 'https://www.nike.com.br/journal/habitos-saudaveis',
          },
          {
            id: 5,
            title: 'Como treinar em casa',
            link: 'https://www.nike.com.br/journal/como-treinar-em-casa',
          },
          {
            id: 6,
            title: 'O que é autocuidado',
            link: 'https://www.nike.com.br/journal/o-que-e-autocuidado',
          },
          {
            id: 7,
            title: 'Treino HIIT',
            link: 'https://www.nike.com.br/journal/treino-hiit',
          },
          {
            id: 8,
            title: 'Tipos de flexão',
            link: 'https://www.nike.com.br/journal/tipos-de-flexao',
          },
        ],
      },
      {
        id: 3,
        title: 'Casual',
        items: [
          {
            id: 1,
            title: 'Guia estilo streetwear',
            link: 'https://www.nike.com.br/journal/guia-estilo-streetwear',
          },
          {
            id: 2,
            title: 'Looks casuais',
            link: 'https://www.nike.com.br/journal/look-casual-combinacoes',
          },
          {
            id: 3,
            title: 'Sneakerhead',
            link: 'https://www.nike.com.br/journal/o-que-e-sneakerhead',
          },
          {
            id: 4,
            title: 'Como limpar tênis branco',
            link: 'https://www.nike.com.br/journal/como-limpar-tenis-branco',
          },
        ],
      },
      {
        id: 4,
        title: 'Mais esportes',
        items: [
          {
            id: 1,
            title: 'Benefícios da atividade física',
            link: 'https://www.nike.com.br/journal/beneficios-da-atividade-fisica',
          },
          {
            id: 2,
            title: 'Calistenia para iniciantes',
            link: 'https://www.nike.com.br/journal/calistenia-para-iniciantes',
          },
          {
            id: 3,
            title: 'Dicas de skate',
            link: 'https://www.nike.com.br/journal/dicas-de-como-andar-de-skate',
          },
          {
            id: 4,
            title: 'Tipos de chuteira',
            link: 'https://www.nike.com.br/journal/tipos-de-chuteira',
          },
          {
            id: 5,
            title: 'Tempo de partida de basquete',
            link: 'https://www.nike.com.br/journal/tempo-partida-basquete',
          },
          {
            id: 6,
            title: 'Posições de Yoga',
            link: 'https://www.nike.com.br/journal/posicoes-de-yoga',
          },
          {
            id: 4,
            title: 'História do basquete',
            link: 'https://www.nike.com.br/journal/a-historia-do-basquete',
          },
          {
            id: 4,
            title: 'Skate para iniciantes',
            link: 'https://www.nike.com.br/journal/skate-para-iniciantes',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'Promoções',
    subMenu: [
      {
        id: 1,
        title: 'Destaques',
        items: [
          {
            id: 1,
            title: 'Produtos em promoção',
            link: 'https://www.nike.com.br/nav/ofertas/emoferta',
          },
          {
            id: 2,
            title: 'Tênis em promoção',
            link: 'https://www.nike.com.br/nav/categorias/tenis/ofertas/emoferta',
          },
          {
            id: 3,
            title: 'Mochilas em promoção',
            link: 'https://www.nike.com.br/nav/ofertas/emoferta/tipodebolsasmochilas/mochilas/tipodebolsasmochilas/sacolas',
          },
          {
            id: 4,
            title: 'Chuteiras em promoção',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Chuteira futsal em promoção',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/ofertas/emoferta/soladodachuteira/futsal',
          },
          {
            id: 6,
            title: 'Air Max em promoção',
            link: 'https://www.nike.com.br/nav/ofertas/emoferta/tecnologia/nikeairmax',
          },
          {
            id: 7,
            title: 'Dunk em promoção',
            link: 'https://www.nike.com.br/nav/modelos/dunk/ofertas/emoferta',
          },
          {
            id: 8,
            title: 'Tênis cano alto em promoção',
            link: 'https://www.nike.com.br/nav/alturadocano/canoalto/categorias/tenis/ofertas/emoferta',
          },
        ],
      },
      {
        id: 2,
        title: 'Feminino',
        items: [
          {
            id: 1,
            title: 'Feminino em promoção',
            link: 'https://www.nike.com.br/nav/genero/feminino/ofertas/emoferta',
          },
          {
            id: 2,
            title: 'Tênis feminino em promoção',
            link: 'https://www.nike.com.br/nav/categorias/tenis/genero/feminino/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Roupa feminina em promoção',
            link: 'https://www.nike.com.br/nav/genero/feminino/ofertas/emoferta/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Tênis de corrida em promoção',
            link: 'https://www.nike.com.br/nav/esportes/corrida/genero/feminino/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 5,
            title: 'Chinelos em promoção',
            link: 'https://www.nike.com.br/nav/categorias/chinelossandalias/genero/feminino/ofertas/emoferta',
          },
          {
            id: 6,
            title: 'Leggings em promoção',
            link: 'https://www.nike.com.br/nav/categorias/calcasleggings/genero/feminino/ofertas/emoferta',
          },
          {
            id: 7,
            title: 'Shorts em promoção',
            link: 'https://www.nike.com.br/nav/categorias/shorts/genero/feminino/ofertas/emoferta',
          },
          {
            id: 8,
            title: 'Air Force 1 em promoção',
            link: 'https://www.nike.com.br/nav/genero/feminino/modelos/airforce1/ofertas/emoferta',
          },
        ],
      },
      {
        id: 3,
        title: 'Masculino',
        items: [
          {
            id: 1,
            title: 'Masculino em promoção',
            link: 'https://www.nike.com.br/nav/genero/masculino/ofertas/emoferta',
          },
          {
            id: 2,
            title: 'Tênis masculino em promoção',
            link: 'https://www.nike.com.br/nav/categorias/tenis/genero/masculino/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Roupa masculina em promoção',
            link: 'https://www.nike.com.br/nav/genero/masculino/ofertas/emoferta/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Mochila em promoção',
            link: 'https://www.nike.com.br/nav/categorias/bolsasmochilas/genero/masculino/ofertas/emoferta/tipodeproduto/acessorios',
          },
          {
            id: 5,
            title: 'Chinelos em promoção',
            link: 'https://www.nike.com.br/nav/categorias/chinelossandalias/genero/masculino/ofertas/emoferta',
          },
          {
            id: 6,
            title: 'Air Max em promoção',
            link: 'https://www.nike.com.br/nav/genero/masculino/ofertas/emoferta/tecnologia/nikeairmax',
          },
          {
            id: 7,
            title: 'Shorts em promoção',
            link: 'https://www.nike.com.br/nav/categorias/shorts/genero/masculino/ofertas/emoferta',
          },
          {
            id: 8,
            title: 'Academia em promoção',
            link: 'https://www.nike.com.br/nav/esportes/treinoacademia/genero/masculino/ofertas/emoferta',
          },
        ],
      },
      {
        id: 4,
        title: 'Infantil',
        items: [
          {
            id: 1,
            title: 'Infantil em promoção',
            link: 'https://www.nike.com.br/nav/idade/infantil/ofertas/emoferta',
          },
          {
            id: 2,
            title: 'Tênis infantil em promoção',
            link: 'https://www.nike.com.br/nav/idade/infantil/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 3,
            title: 'Roupa infantil em promoção',
            link: 'https://www.nike.com.br/nav/idade/infantil/ofertas/emoferta/tipodeproduto/roupas',
          },
          {
            id: 4,
            title: 'Chuteira infantil em promoção',
            link: 'https://www.nike.com.br/nav/categorias/chuteiras/idade/infantil/ofertas/emoferta',
          },
          {
            id: 5,
            title: 'Tênis rosa em promoção',
            link: 'https://www.nike.com.br/nav/cor/rosa/idade/infantil/ofertas/emoferta/tipodeproduto/calcados',
          },
          {
            id: 6,
            title: 'Calçados tamanho 30',
            link: 'https://www.nike.com.br/nav/idade/infantil/ofertas/emoferta/tamanho/30/tipodeproduto/calcados',
          },
          {
            id: 7,
            title: 'Mochila infantil em promoção',
            link: 'https://www.nike.com.br/nav/idade/infantil/ofertas/emoferta/tipodebolsasmochilas/mochilas/tipodebolsasmochilas/sacolas',
          },
          {
            id: 8,
            title: 'Shorts infantil em promoção',
            link: 'https://www.nike.com.br/nav/categorias/shorts/genero/feminino/idade/infantil/ofertas/emoferta',
          },
        ],
      },
    ],
  },
];
