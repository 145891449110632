import { Link as Anchor, Typography } from '@sbf/fairplay-nike';
import { media } from '@sbf/fairplay-utils';
import styled, { css } from 'styled-components';

export const SectionTitle = styled(Typography)`
  font-size: 20px;
`;

export const LinkAnchor = styled(Anchor)`
  ${({ theme }) => css`
    color: ${theme.colors.neutral[500]};
    font-size: ${theme.fontSizes.medium};
    font-weight: ${theme.fontWeights.medium};
    text-decoration: none;
  `}
`;

export const LinkWrapper = styled.ul`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: ${theme.spaces[0]};
  `}
`;

export const SectionTitleButton = styled.button<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    align-items: center;
    background: ${theme.colors.neutral[100]};
    border: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 20px;
    margin: ${theme.spaces[400]} auto ${theme.spaces[400]};
    outline: none;
    box-sizing: border-box;
    padding: ${theme.spaces[300]};
    border-bottom: ${selected
      ? `${theme.borderWidth.thin} solid ${theme.colors.primary}`
      : 'none'};
    color: ${selected
      ? `${theme.colors.primary}`
      : `${theme.colors.neutral[600]}`};

    ${media.greaterThan('desktop')} {
      margin: ${theme.spaces[300]} 0 ${theme.spaces[400]} 0;
      width: 70%;
    }
    ::-webkit-scrollbar {
      display: none;
    }
    ${media.greaterThan('tablet')} {
      flex-flow: row wrap;
    }
  `}
`;
