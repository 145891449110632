import { media } from '@sbf/fairplay-utils';
import styled from 'styled-components';

export const StyledMain = styled.main`
  display: grid;
  column-gap: 0;
  box-sizing: border-box;
  & > * {
    grid-column: 2;
  }

  grid-template-columns: minmax(0, 1fr) min(85vw, 100vw) minmax(0, 1fr);

  ${media.greaterThan('desktop')} {
    grid-template-columns: 1fr min(100vw, 100%) 1fr;
  }
`;
