import { Typography } from '@sbf/fairplay-core';
import Link from 'next/link';
import {
  LinkAnchor,
  LinkWrapper,
  SectionTitle,
} from './TransactionalFooterCard.styled';
import { TransactionalFooterCardProps } from './TransactionalFooterCard.type';

export const TransactionalFooterCard = ({
  subMenu,
}: TransactionalFooterCardProps) => {
  return (
    <LinkWrapper>
      {subMenu?.map(({ id, title, items }) => {
        return (
          <div key={id}>
            <SectionTitle key={id} variant="heading" size="h2">
              {title}
            </SectionTitle>
            <div>
              {items?.map(({ link, title, id }) => (
                <Typography variant="heading" size="h1" key={id}>
                  <Link key={id} href={link} legacyBehavior passHref>
                    <LinkAnchor type="quiet" text={title} />
                  </Link>
                </Typography>
              ))}
            </div>
          </div>
        );
      })}
    </LinkWrapper>
  );
};
