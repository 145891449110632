/**
 * The home components id
 * @readonly
 */
export enum HOME_COMPONENTS {
  FULL_BANNER = 119,
  SPACER = 7,
  DYNAMIC_PLAYLIST = 116,
  ROTATIVE_BANNER = 111,
  MAIN_BANNER = 101,
  RECOMMENDATION = 115,
  RECOMMENDATION_MOBILE = 109,
  VIDEO = 120,
  BANNER_BUTTONS = 121,
}

export enum POSITIONS {
  CENTER = 0,
  END = 1,
  START = 2,
}

export type FullBanner = {
  tipoElemento: HOME_COMPONENTS.FULL_BANNER;
  bannerFull: {
    linkExterno: number;
    urlLink: string;
    eventoClick: string;
    atributoEvento: string;
    urlImagem: string;
    altura: number;
    largura: number;
    adicional: number;
    opcaoVertical: number;
    opcaoHorizontal: number;
    texto: string | null;
    cor: string | null;
    url_LinkAdicional: string | null;
    eventoClickAdicional: string | null;
    atributoEventoAdicional: string | null;
    limitadoGrid: number;
  };
};

export type Spacer = {
  tipoElemento: HOME_COMPONENTS.SPACER;
  altura: number;
  cor: string;
  titulo?: string;
  corTitulo: string;
  tamanhoTitulo?: number;
};

type ColumnDynamicPlaylist = {
  ordem: number;
  nomeSubcategoria: string | null;
  linkExterno: number;
  urlLink: string;
  eventoClick: string;
  atributoEvento: string;
  urlImagem: string;
  altura: number;
  largura: number;
};

type LineDynamicPlaylist = {
  ordem: number;
  tamanhoFonte: number;
  direcionamento: null | unknown;
  CorBotao: number;
  TipoPlaylist: number;
  colunas: ColumnDynamicPlaylist[];
  tituloMenu: string | null;
};

export type DynamicPlaylist = {
  tipoElemento: HOME_COMPONENTS.DYNAMIC_PLAYLIST;
  linhas: LineDynamicPlaylist[];
};

type LinkRotativeBanner = {
  ordem: number;
  tamanhoFonte: number;
  urlImagem: string;
  altura: number;
  largura: number;
  direcionamento: null | unknown;
  CorBotao: number;
  TipoPlaylist: number;
  urlLink: string;
  eventoClick: string;
  atributoEvento: string;
  linkExterno: number;
  tituloMenu: string | null;
};

export type RotativeBanner = {
  tipoElemento: HOME_COMPONENTS.ROTATIVE_BANNER;
  listaLinks: LinkRotativeBanner[];
  tempoParaTransicao?: number;
  inicioDoTimerDoBanner?: string;
  fimDoTimerDoBanner?: string;
};

type LinkBanner = {
  ordem: number;
  cor: string;
  tamanhoFonte: number;
  urlImagem: string;
  altura: number;
  largura: number;
  direcionamento: null | unknown;
  CorBotao: number;
  TipoPlaylist: number;
  urlLink: string;
  eventoClick: string;
  atributoEvento: string;
  linkExterno: number;
  tituloMenu: string | null;
};

export type MainBanner = {
  tipoElemento: HOME_COMPONENTS.MAIN_BANNER;
  listaLinks: LinkBanner[];
};

export type Video = {
  tipoElemento: HOME_COMPONENTS.VIDEO;
  video: {
    url: string;
    altura: number;
    largura: number;
    autoplay: boolean;
    loop: boolean;
    controles: boolean;
    posterUrl: string;
  };
};

export type BannerButtons = {
  tipoElemento: HOME_COMPONENTS.BANNER_BUTTONS;
  margemSuperior?: number;
  margemInferior?: number;
  botoes: {
    titulo: string;
    link: string;
  }[];
};

type ComponentVariant =
  | FullBanner
  | DynamicPlaylist
  | MainBanner
  | RotativeBanner
  | Spacer
  | Recommendation
  | Video
  | BannerButtons;

export type CommonComponent = {
  ordem: number;
  tituloElemento: string;
  produtosVitrineCuponada: unknown[];
  pesoFonte: number;
  posicaoTitulo: POSITIONS;
  SwapMaisVendidos: null | unknown;
  rolagemAutomatica: boolean;
  exibirIndicadorDeTransicao: boolean;
  exibirTemporizadorRegressivo: boolean;
  isFirstComponent?: boolean;
  index?: number;
  vitrineChaordic: ChaordicShowcase | null;
};

export type Component = ComponentVariant & CommonComponent;

export type Home = {
  id: number;
  conteudoJson: Component[];
};

export type ChaordicShowcase = {
  TipoVitrineChaordicDesk: number;
};

export type Recommendation = {
  tipoElemento: HOME_COMPONENTS.RECOMMENDATION;
  vitrineChaordic: ChaordicShowcase;
  listaLinks: LinkRotativeBanner[];
  inicioDoTimerDoBanner?: string;
  fimDoTimerDoBanner?: string;
};
